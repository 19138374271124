import './UserModal.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import UserTags from "../tools/UserTags";
import {useParams} from "react-router";
import { UserModal } from "./UserModal";

export class ViewUserModal extends React.Component {
    user_type = 'user';
    initializing = true;

    constructor(props) {
        super(props);
        this.state = {
            is_loading: null,
            account: null,
        };
        if (Object.getPrototypeOf(this) === ViewUserModal.prototype) {
            this.componentDidUpdate({}, {}, {}); // Force data query only when not called via super()
        }
    }

    _getApi() {
        return new WebsiteApi();
    }

    _getVisitor() {
        return this.props?.context?.visitor;
    }

    _isCurrentUserLoaded(id) {
        if (this.state.is_loading === false && !this.state.account?.account?.id) {
            // Has failed -> ignore
            return true;
        }
        return this.state.account?.account?.id === parseInt(id, 10);
    }

    loadUserData(id) {
        this.setState({
            is_loading: true,
        });
        this._getApi().getAccount(id, this.user_type).then((account_data) => {
            this.setState({
                account: account_data,
            });
        }).finally(() => {
            this.setState({
                is_loading: false,
            });
        }); // @todo: HANDLE ERRORS
    }

    getLoadingView() {
        return (
            <>
                Latailee..
            </>
        );
    }

    _getUserName(visitor_account) {
        return (visitor_account?.account?.username ? visitor_account.account.username : visitor_account.anonymous_name);
    }

    _getUserAvatar(account) {
        if (!account?.img) {
            return null;
        }
        return (
            <div className="User-Profile-Image">
                <img src={account.img} alt={`Käyttäjän avatar: ${account?.name || 'Player Unknown'}`} />
            </div>
        );
    }

    getPageMetadata() {
        const visitor_account = this.state.account;
        if (!visitor_account) {
            return {};
        }
        return {
            title: this._getUserName(visitor_account),
            ogDesc: 'Käyttäjä: ' + this._getUserName(visitor_account),
            ldType: 'WebPage',
            excludeFromIndex: true,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.initializing && this.state.is_loading) {
            // Already loading.. ignore (will get back here once loading completes)
            return;
        }
        const cleanUri = (uri) => {
            if (!uri) {
                return uri;
            }
            return uri.replace(/\?[^#]+/, '').replace(/#.*$/, ''); // Remove #state, if there's one; also, remove query parameters
        }
        const id = this.props?.id;
        const uri = cleanUri(id);
        const uri_changed = (uri !== cleanUri(prevProps?.id));
        if (uri_changed) {
            this.loadUserData(id);
        }
        if (this.initializing) {
            this.initializing = false;
        }
        window._onPageChange(this, uri, false);
    }

    render() {
        //const visitor = this._getVisitor();
        if (this.initializing || this.state.is_loading) {
            return this.getLoadingView();
        }
        const visitor_account = this.state.account;
        const registered_account = visitor_account?.account;
        if (!visitor_account?.anonymous_name || !registered_account?.id) {
            return (
                <>
                    Myyrä on kolossaan, profiilia ei löytynyt!
                </>
            );
        }
        const stats_and_tags = UserModal.getUserStats(visitor_account);

        return (
            <div className="Modal-Wrapper" onClick={() => this.props.navigate('/')}>
                <div className="UserModal" onClick={e => e.stopPropagation()}>
                    <div className="User-Profile-Container">
                        <div className="Left-Column">
                            <h2>{this._getUserName(visitor_account)}</h2>
                            {this._getUserAvatar(registered_account)}
                        </div>
                        <div className="Middle-Column">
                            {stats_and_tags}
                        </div>
                        <div className="Right-Column"></div>
                    </div>
                </div>
            </div>
        );
    }
}

function WithNavigateAndContext(props) {
    const { id } = useParams();
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <ViewUserModal {...props} navigate={navigate} context={context} id={id} />
        }}
    </AppContext.Consumer>)
}

export default WithNavigateAndContext;