import './NewsBox.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import WebsiteApi from "../WebsiteApi";
import Loading from "../Loading";

let onScrollListenerRef;

export class NewsBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'news': this.props?.context?.components?.news || [],
            'isLoadingMore': false,
            'noMoreNews': false,
            'showLoadMoreButton': false,
        };
    }

    _getApi() {
        return new WebsiteApi();
    }

    openArticle(article) {
        if (!article.link) {
            return;
        }
        this.props.navigate(article.link);
    }

    getNewsContent() {
        const news = this.state?.news;
        let news_elements = [];
        for (let article of news) {
            news_elements.push((
                <li className="NewsBox-Article-List-Item">
                    <a href={article.link} onClick={(e) => {
                        e.preventDefault();
                        this.openArticle(article);
                    }}>
                        <span className="Label NewsBox-Article-Date-Label">{article?.published_at}</span>
                        <img src={article?.thumb_img || article.img} alt={`Kansikuva: ${article.topic}`} title={article.topic} className="NewsBox-Article-Image" />
                        <span>{article.topic}</span>
                    </a>
                </li>
            ));
        }
        let scroll_direction = 'horizontal';
        if (this.props?.fullpage) {
            scroll_direction = 'vertical';
        }
        return (
            <div className="NewsBox-Article-List-Container" onScroll={(e) => {
                this.onNewsBoxScroll(e);
            }} data-scroll-direction={scroll_direction}>
                <ul className="NewsBox-Article-List">
                    {news_elements}
                </ul>
            </div>
        );
    }

    _getTotalScrollHeight(news_box) {
        if (!news_box) {
            news_box = document.querySelector('div.NewsBox-Article-List-Container');
            if (!news_box) {
                return null;
            }
        }
        return news_box.scrollHeight - news_box.clientHeight;
    }

    onNewsBoxScroll(e) {
        if (this.state.isLoadingMore || this.state.noMoreNews) {
            // Ignore event while we are loading more news
            return;
        }
        const news_box = e.target;
        if (!news_box) {
            return;
        }
        if (this.props?.fullpage) {
            // Note! Vertical scroll
            const totalScrollHeight = this._getTotalScrollHeight(news_box);
            const currentScrollPosition = news_box.scrollTop;
            if (currentScrollPosition >= 0.8 * totalScrollHeight) {
                this._loadMoreNews(news_box);
            }
            return;
        }
        // Note! Horizontal scroll
        const totalScrollWidth = news_box.scrollWidth - news_box.clientWidth;
        const currentScrollPosition = news_box.scrollLeft;
        if (currentScrollPosition >= 0.8 * totalScrollWidth) {
            this._loadMoreNews(news_box);
        }
    }

    getTag() {
        return null; // News only, do not filter by tag
    }

    loadMore() {
        this._loadMoreNews();
    }

    _loadMoreNews(news_box) {
        this.setState({
            'isLoadingMore': true,
        });
        let ts = null;
        if (this.state?.news?.length > 0) {
            ts = this.state.news[this.state.news.length - 1].published_on_ts || null;
        }
        return this._getApi().getLatestNews(ts, this.getTag()).then((data) => {
            if (data?.response?.status > 299) {
                // An error occurred
                console.error('Failed to retrieve latest news!');
                return data;
            }
            for (let article of data) {
                this.state.news.push(article);
                if (this.state.news.length > 50) {
                    this.state.news.shift(); // Remove the first item from the array to keep it sensible, i.e. don't show thousands of news items on the component if open for whole day
                }
            }
            if (this.state.news.length === 50 && news_box) {
                if (this.props?.fullpage) {
                    // Note! Vertical scroll
                    news_box.scrollTop = news_box.scrollTop - (news_box.scrollTop * 0.1); // scroll back 10%
                } else {
                    // Note! Horizontal scroll
                    news_box.scrollLeft = news_box.scrollLeft - (news_box.scrollLeft * 0.1); // scroll back 10%
                }
            }
            const noMoreNews = (data.length < 10);
            this.setState({
                'news': this.state.news,
                'isLoadingMore': false,
                'noMoreNews': noMoreNews,
            });
            return data;
        }).catch(() => {
            this.setState({
                'isLoadingMore': false,
                'noMoreNews': true,
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isLoadingMore) {
            // ..already loading, ignore
            return;
        }
        if (!this.props?.fullpage) {
            // ..not in fullpage mode -> do not retrieve more
            return;
        }
        if (this.state.noMoreNews) {
            // ..there is no more news to retrieve
            return;
        }
        if (this.state.news.length >= 50) {
            // ..already maxed out..
            // ..but there is no scroll -> show button to load more!
            if (!this.state?.showLoadMoreButton) {
                this.setState({
                    showLoadMoreButton: true,
                });
            }
            return;
        }
        setTimeout(() => {
            const totalScrollHeight = this._getTotalScrollHeight();
            if (totalScrollHeight === null || totalScrollHeight > 0) {
                // ..either can not access the component..
                // ..or there is already enough news articles to provide scroll for the element
                return;
            }
            this._loadMoreNews();
        }, 500);
    }

    getLoadMoreButton() {
        if (!this.state?.showLoadMoreButton) {
            return null;
        }
        return (
            <>
                <a href="" onClick={(e) => {
                    e.preventDefault();
                    if (this.state.isLoadingMore) {
                        return;
                    }
                    this._loadMoreNews();
                }} className="Full-Width-Button">Näytä lisää</a>
            </>
        );
    }

    getTitle() {
        return 'Uutiset';
    }

    render() {
        const news = this.state?.news;
        let content;
        if (news?.length > 0) {
            content = (
                <>
                    {this.getNewsContent()}
                    {this.getLoadMoreButton()}
                </>
            );
        } else {
            content = (
                <>
                    <Loading inline={true} style={{width: '100px', margin: '0 auto'}} />
                </>
            );
        }
        return (
            <div className="Community-Box">
                <h2 className="Community-Box-Title">{this.getTitle()}</h2>
                {content}
            </div>
        );
    }
}

const NewsBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <NewsBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default NewsBoxWithContext;

