import './Footer.css';
import React from 'react';
import {LogoEmblem} from "../images/LogoEmblem";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    openLink(e, news_article) {
        e.preventDefault();
        this.props.navigate(news_article.link);
        window.scrollTo(0, 0); // Scroll to top
    }

    getLatestNewsList() {
        if (!this.props.context?.components?.news || !(this.props.context.components.news.length > 0)) {
            return (<p>Latailee</p>);
        }
        let news_elements = [];
        for (let news of this.props.context?.components?.news) {
            if (news_elements.length === 5) {
                // Show only five of latest new headlines in footer
                break;
            }
            news_elements.push((
                <>
                    <li>
                        <a href={news.link} onClick={(e) => { this.openLink(e, news); }}>{news.topic}</a>
                    </li>
                </>
            ));
        }
        return (
            <ul className="Latest-News-List">
                {news_elements}
            </ul>
        );
    }

    render() {
        return (
            <footer>
                <div className="Footer-Main">
                    <div className="col-md-3 col-sm-12">
                        <a href="/" aria-label="Pelaajat.com etusivu">
                            <div className="Logo"><LogoEmblem /></div>
                        </a>
                    </div>

                    <div className="col-md-3 col-sm-4 Footer-Info">
                        <h2 className="Footer-Title">PELAAJAT.COM</h2>
                        <p className="Footer-Content">Pelaajat.com tarjoaa sinulle parhaat kilpaliigat, hermeettiset pelilähetykset &amp; tuoreimmat uutiset e-urheilusta</p>

                        <div className="Footer-Contact">
                            <h4>YHTEYDENOTOT</h4>
                            <a href="mailto:info@pelaajat.com">info@pelaajat.com</a>
                        </div>

                        <div className="Footer-Sponsor">
                            <h4>Sponsorit ja yhteistyö</h4>
                            <a href="mailto:info@pelaajat.com">info@pelaajat.com</a>
                        </div>

                        <div className="Footer-Social clear">
                            <ul>
                                <li>
                                    <a href="https://www.twitch.tv/pelaajatcom" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'twitch', style: 'brands'})} style={{color: "#ff3300",}} /> twitch
                                    </a>
                                </li>
                                <li>
                                    <a href="https://x.com/pelaajatcom" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} style={{color: "#ff3300",}} /> X
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/pelaajatcom/" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'instagram', style: 'brands'})} style={{color: "#ff3300",}} /> instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/Pelaajatcom/" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'facebook', style: 'brands'})} style={{color: "#ff3300",}} /> facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/Pelaajat" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'youtube', style: 'brands'})} style={{color: "#ff3300",}} /> youtube
                                    </a>
                                </li>
                                <li>
                                    <a href="https://pelaajat.com/out/discord" target="_blank">
                                        <FontAwesomeIcon icon={icon({name: 'discord', style: 'brands'})} style={{color: "#ff3300",}} /> discord
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-4">
                        <h2 className="Footer-Title"> UUSIMMAT UUTISET</h2>
                        {this.getLatestNewsList()}
                    </div>

                    <div className="col-md-3 col-sm-4">
                        {/**<h2 className="Footer-Title"> UUSIMMAT VIDEOT</h2>
                        <p>TBD</p>**/}
                    </div>
                </div>

                <div className="Footer-Bottom-Bar">
                    <div className="Footer-Menu-Wrapper">
                        <ul className="Footer-Menu">
                            <li><a href="/tietosuojaseloste">Tietosuojaseloste</a></li>
                            <li>
                                <a href="" onClick={(e) => { e.preventDefault(); window.__tcfapi('displayConsentUi', 2, function(response) {
                                    console.log('Consent UI re-triggered:', response);
                                });}}>Eväste- ja tietosuoja-asetukset</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </footer>
        );
    }
}

const FooterWithContext = (props) => {
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <Footer {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};
export default FooterWithContext;
