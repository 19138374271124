import './LinksNavigation.css';
import React from 'react';
import {Link} from "react-router-dom";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppContext} from "../App";
import { useLocation } from 'react-router-dom';

class LinksNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {uri: '/yhteis%C3%B6', name: "Yhteisö"},
                {uri: '/uutiset', name: "Uutiset"},
                //{uri: '/kauppa', name: "PelShop"},
                {uri: '/yhteis%C3%B6edut', name: "Yhteisöedut"},
                {uri: '/bootcamp', name: "Bootcamp"},
                {uri: '/saunatilat', name: "Sauna"},
                {uri: '/turnaukset', name: "Turnaukset"},
            ],
            isMenuOpen: false,
        };
    }

    _getLinks() {
        let links = [
            (<li className="Divider">|</li>),
        ];
        for (const index in this.state.links) {
            const link = this.state.links[index];
            let link_css_class = null;
            if (this.props?.location?.pathname) {
                let uri_match = new RegExp(`${link.uri}([\/\?\#]|$)`).test(this.props.location.pathname);
                if (!uri_match) {
                    uri_match = new RegExp(`${encodeURIComponent(link.uri).replace(encodeURIComponent('/'), '/')}([\/\?\#]|$)`).test(this.props.location.pathname);
                }
                if (uri_match) {
                    link_css_class = 'Active-Link';
                }
            }
            links.push((
                <li className={link_css_class}><Link to={link.uri}>{link.name}</Link></li>
            ));
            links.push((<li className="Divider">|</li>));
        }
        return links;
    }

    windowOnClickHandler(e) {
        if (this.state.isMenuOpen === false) {
            // Ignore, menu is hidden!
            return;
        }
        if (e.target.closest('.Links-Navigation') || e.target.closest('.Links-Navigation-Mobile-Menu') || e.target.closest('.Links-Navigation-Mobile-Menu-Close')) {
            // Allow clicks on the mobile menu
            if (e.target.tagName.toLowerCase() === 'a') {
                // ..menu link is clicked -> hide menu!
                document.removeEventListener('click', this.windowOnClickHandler.bind(this));
                this.setState({
                    isMenuOpen: false,
                });
            }
            return;
        }
        e.preventDefault();
        document.removeEventListener('click', this.windowOnClickHandler.bind(this));
        this.setState({
            isMenuOpen: false,
        });
    }

    render() {
        let menuOpenButtonStyles = null;
        if (this.state.isMenuOpen) {
            menuOpenButtonStyles = {display: 'none'};
        }
        return (
            <>
                <button className="Links-Navigation-Mobile-Menu" aria-labelledby="Mobile-Menu-Label"
                        style={menuOpenButtonStyles} onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        isMenuOpen: true,
                    });
                    document.addEventListener('click', this.windowOnClickHandler.bind(this));
                }}>
                    <FontAwesomeIcon icon={icon({name: 'bars', style: 'solid'})} style={{color: "#fff",}}/>
                    <span id="Mobile-Menu-Label" hidden>Avaa navigaatiomenu</span>
                </button>
                <button className="Links-Navigation-Mobile-Menu-Close" aria-labelledby="Mobile-Menu-Close-Label"
                        style={{display: (this.state.isMenuOpen ? 'block' : 'none')}} onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        isMenuOpen: false,
                    });
                }}>
                    <FontAwesomeIcon icon={icon({name: 'bars', style: 'solid'})} style={{color: "#fff",}}/>
                    <span id="Mobile-Menu-Close-Label" hidden>Sulje navigaatiomenu</span>
                </button>
                <div className="Links-Navigation">
                <ul>
                        {this._getLinks()}
                    </ul>
                </div>
            </>
        );
    }
}

const LinksNavigationWithContext = (props) => {
    const location= useLocation();
    return (<AppContext.Consumer>
        {(context) => {
            return <LinksNavigation {...props} location={location} context={context} />
        }}
    </AppContext.Consumer>);
};
export default LinksNavigationWithContext;
