import './CommunityFeedBox.css';
import React from 'react';
import {AppContext} from "../App";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Loading from "../Loading";

class CommunityFeedBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullpage: this.props?.fullpage,
            is_loading: false,
            noMoreFeedItems: false,
            page: 0,
        };
    }

    _getFeed() {
        if (!this.state?.fullpage && this.props.context?.community) {
            let community_feed = this.props.context.community;
            return community_feed.slice(0, 5)
        }
        return this.props.context?.community;
    }

    openResource(e, feed_item) {
        e.preventDefault();
        if (feed_item?.link) {
            this.props.navigate(feed_item.link);
        }
    }

    _getFeedItems() {
        const feed = this._getFeed();
        const full_page_mode = this._isFullPageMode();

        let feed_items = [];
        for (const feed_item of feed) {
            let author = '';
            if (full_page_mode && feed_item?.author?.img) {
                author = (
                    <div className="Community-Box-Feed-Author-Column">
                        <div>
                            <img src={feed_item.author.img} alt={`Kirjoittajan avatar: ${feed_item.author?.name}`} />
                        </div>
                        <strong>{feed_item.author?.name || ''}</strong>
                    </div>
                );
            }

            let item_img = '';
            if (feed_item?.img) {
                item_img = (
                    <img src={feed_item.img} alt={`Kansikuva: ${feed_item.topic}`} />
                );
            }

            feed_items.push((
                <div className="Community-Box-Feed-Entry">
                    {author}
                    <div className="Community-Box-Feed-Topic-Column">
                        <div className="Community-Box-Feed-Item-Image" onClick={(e) => { this.openResource(e, feed_item); }}>
                            {item_img}
                        </div>
                        <Link to={feed_item.link}>
                            {feed_item.topic}
                        </Link>
                        <div className="Community-Box-Feed-Latest-Comment">
                            <FontAwesomeIcon icon={icon({name: 'comment'})} /> {feed_item['+latest_comment_content']}
                        </div>
                    </div>
                    <div className="Community-Box-Feed-Time">
                        {feed_item['+latest_comment']}
                    </div>
                    <div className="Community-Box-Feed-Stats">
                        <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {feed_item.likes} <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {feed_item.dislikes} <FontAwesomeIcon icon={icon({name: 'comment'})} /> {feed_item.comments}
                    </div>
                </div>
            ));
        }

        if (this.state?.is_loading) {
            feed_items.push((<Loading inline={true} />));
        }

        return (
            <>
                {feed_items}
            </>
        );
    }

    _isFullPageMode() {
        return !!this.state?.fullpage;
    }

    _getTotalScrollHeight(feed_box) {
        if (!feed_box) {
            feed_box = document.querySelector('div.Community-Box-Feed');
            if (!feed_box) {
                return null;
            }
        }
        return feed_box.scrollHeight - feed_box.clientHeight;
    }

    loadMore(feed_box) {
        const feed = this.props?.context?.community;
        if (!feed || feed.length < 10) {
            // Ignore, since no more data available
            return;
        }

        const next_page = this.state.page + 1;
        this.setState({
            'is_loading': true,
            page: next_page,
        });
        const previous_item_cnt = this.props?.context?.community.length;
        window._communityIndexPagination = next_page;
        const previous_scrollTop_pos = feed_box.scrollTop;
        window._forceUpdate().finally(() => {
            feed_box.scrollTop = previous_scrollTop_pos;
            this.setState({
                is_loading: false,
            });
            const noMoreFeedItems = (this.props?.context?.community.length - previous_item_cnt < 10);
            this.setState({
                'is_loading': false,
                'noMoreFeedItems': noMoreFeedItems,
            });
        });
    }

    onFeedBoxScroll(e) {
        if (!this._isFullPageMode()) {
            return;
        }
        if (this.state.is_loading || this.state.noMoreFeedItems) {
            // Ignore event while we are loading more news
            return;
        }
        const feed_box = e.target;
        if (!feed_box) {
            return;
        }
        const totalScrollHeight = this._getTotalScrollHeight(feed_box);
        const currentScrollPosition = feed_box.scrollTop;
        if (currentScrollPosition >= 0.8 * totalScrollHeight) {
            this.loadMore(feed_box);
        }
    }

    getCommunityFeedContent() {
        const feed = this._getFeed();
        if (!feed) {
            return (
                <Loading inline={true} />
            );
        }
        return (
            <div className="Community-Box-Feed" data-fullpage={this._isFullPageMode() ? 'true' : ''} onScroll={(e) => {
                this.onFeedBoxScroll(e);
            }}>
                {this._getFeedItems()}
            </div>
        )
    }

    render() {
        return (
            <div className="Community-Box">
                <h2 className="Community-Box-Title">Yhteisö</h2>
                {this.getCommunityFeedContent()}
            </div>
        );
    }
}

const CommunityFeedBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityFeedBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default CommunityFeedBoxWithContext;

