import React from 'react';
import CommunityFeedBox from "../boxes/CommunityFeedBox";
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import {AppContext} from "../App";
import Tools from "../tools/Tools";

class CommunityIndexPage extends React.Component {
    constructor(props) {
        super(props);
        this._props = props;
        this.state = {};

        if (window._forceUpdate) {
            window._forceUpdate();
        }
    }

    getContentColumnContents() {
        return (
            <>
                <CommunityFeedBox fullpage="true" />
            </>
        );
    }

    render() {
        return (
            <div className="Content">
                <ContentColumn content={this.getContentColumnContents()} />
                <SideBar />
            </div>
        );
    }
}

const CommunityIndexPageWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityIndexPage {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default CommunityIndexPageWithContext;
