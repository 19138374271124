import './AdBox.css';
import React from 'react';
import { AppContext } from '../App.js'

class AdBox extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        if (window.relevantDigital && relevantDigital.destroySlots) {
            // @see https://help.relevant-digital.com/knowledge/relevantdigital.destroyslots
            relevantDigital.destroySlots();
            window._ad_init_done = false;
        }
    }

    componentDidMount() {
        this.popMediaAds();
    }

    componentDidUpdate(prevProps, prevState) {
        this.popMediaAds();
    }

    injectDeviceInfoIntoPopMediaAds() {
        let elements_ok = false;
        const type = window.innerWidth < 980 ? 'mobile' : 'desktop';
        ["data-lazy-" + type + "-ad-unit-id", "data-" + type + "-ad-unit-id"].forEach((attr) => {
            Array.from(document.querySelectorAll("["+ attr + "]")).forEach((elm) => {
                elements_ok = true
                if (elm.getAttribute('data-slot-rendered') === "true") {
                    return;
                }
                elm.setAttribute(attr.replace(type + "-", ""), elm.getAttribute(attr));
                elm.setAttribute('data-slot-rendered', 'true');
            });
        });
        return elements_ok;
    }

    servePopMediaAds() {
        if (window._ad_init_done) {
            // Already defined -> skip!
            return;
        }
        window._ad_init_done = true;

        window.relevantDigital = window.relevantDigital || {};
        relevantDigital.cmd = relevantDigital.cmd || [];
        relevantDigital.cmd.push(function() {
            relevantDigital.loadPrebid({
                configId: '63e4ae374cc636ac3cc16da7', //pelaajatcom config id
                delayedAdserverLoading: true,
                manageAdserver: true,
                collapseEmptyDivs: true,
                collapseBeforeAdFetch: false,
                noGpt: true,
                allowedDivIds: null,
            });
        });
    }

    popMediaAds() {
        const element = document.querySelectorAll('[data-desktop-ad-unit-id]');
        if (!element) {
            return;
        }
        const elements_ok = this.injectDeviceInfoIntoPopMediaAds();
        if (!elements_ok) {
            // DOM not ready, wait!
            return;
        }
        this.servePopMediaAds();
    }

    render() {
        if (!this.props?.position || !this.props?.context?.components?.reklaami) {
            return null;
        }
        const ad = this.props.context.components.reklaami[this.props.position];
        if (ad === false) {
            let adunit_id, adunit_id_mobile = null;
            // Serve PopMedia ads
            switch (this.props.position) {
                case 'article':
                    const ads = [
                        {desktop: 'pelaajatcom_smart_in_content_1_desktop', mobile: 'pelaajatcom_smart_in_content_2'},
                        {desktop: 'pelaajatcom_smart_in_content_2_desktop', mobile: 'pelaajatcom_smart_in_content_3'},
                        {desktop: 'pelaajatcom_rich_media_1_1', mobile: 'pelaajatcom_rich_media_1_1_mobile'},
                    ];
                    adunit_id = ads[Math.floor(Math.random() * ads.length)].desktop;
                    adunit_id_mobile = ads[Math.floor(Math.random() * ads.length)].mobile;
                    break;
                case 'community':
                    adunit_id = 'pelaajatcom_outstream_1_1';
                    adunit_id_mobile = 'pelaajatcom_outstream_1_1';
                    break;
                case 'sidebar':
                    adunit_id = 'pelaajatcom_boksi_300_250_1';
                    adunit_id_mobile = 'pelaajatcom_boksi_300_250_1';
                    break;
                default:
                    break;
            }
            if (adunit_id) {
                if (adunit_id_mobile) {
                    return (
                        <div className="Ad-Box">
                            <div data-desktop-ad-unit-id={'/22871113322/' + adunit_id} data-mobile-ad-unit-id={'/22871113322/' + adunit_id_mobile}></div>
                        </div>
                    );
                }
                return (
                    <div className="Ad-Box">
                        <div data-desktop-ad-unit-id={adunit_id}></div>
                    </div>
                );
            }
        }
        if (!ad) {
            return null;
        }

        if (ad.html) {
            return (
                <div className="Ad-Box" dangerouslySetInnerHTML={{__html: ad.html}} />
            );
        }

        let ad_content = null;
        if (ad.img_url) {
            ad_content = (
                <img src={ad.img_url} alt="Mainos" />
            );
        }
        if (ad.img_link) {
            ad_content = (
                <a href={ad.img_link} target="_blank" rel="nofollow">
                    {ad_content}
                </a>
            );
        }

        return (
            <div className="Ad-Box">
                {ad_content}
            </div>
        );
    }
}

const AdBoxWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <AdBox {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default AdBoxWithContext;
