import './Loading.css';
import React from 'react';
import { LogoEmblem } from './images/LogoEmblem';

class Loading extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const styles = this.props?.style || {};
        const loading_logo = (
            <p className="Loading-logo" style={styles}>
                <LogoEmblem/>
            </p>
        );
        if (this.props?.inline) {
            return loading_logo;
        }

        return (
            <div className="App">
                <main className="Loading-canvas">
                    {loading_logo}
                </main>
            </div>
        );
    }
}

export default Loading;
