import React from 'react';
import {AppContext} from "../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";

class CommunityPickBox extends React.Component {
    constructor(props) {
        super(props);
    }

    _getCommunity() {
        return this.props.context?.components?.community;
    }

    render() {
        const community = this._getCommunity();
        const comment = community?.pick?.comment || {};
        const txt = community?.pick?.comment?.txt;
        const author = community?.pick?.author?.name || 'Anonyymi pelaaja';
        const resource = community?.pick?.resource || {};
        if (!txt || !resource?.uri || !resource.title) {
            return (<div />)
        }

        return (
            <div className="Community-Pick-Box" data-position={this.props?.position}>
                <h2 className="Community-Pick-Title">Nosto</h2>

                <div className="Community-Pick-Quote-Wrapper">
                    <label className="Community-Pick-QuoteResourceTitle">
                        <a href={resource.uri} tabIndex="0">{resource.title}</a>
                    </label>
                    <span className="Community-Pick-Quote">&quot;{txt}&quot;</span>
                    <span className="Community-Pick-QuoteAuthor">— <strong>{author}</strong></span>

                    <div className="Community-Pick-Community-State">
                        <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {comment.likes} <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {comment.dislikes} <FontAwesomeIcon icon={icon({name: 'comment'})} /> {comment.count}
                    </div>
                </div>
            </div>
        );
    }
}

const CommunityPickBoxWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <CommunityPickBox {...props} context={context} />
        }}
    </AppContext.Consumer>)
};

export default CommunityPickBoxWithContext;

