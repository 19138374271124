import axios from 'axios';
import Cookies from "universal-cookie";

class WebsiteApi {
    constructor() {}

    getFromApi(uri, error_handler) {
        return axios
            .get(process.env.REACT_APP_API_URL + uri, { withCredentials: true })
            .then((response) => {
                // @todo: check for errors and toast them?
                if (!response.data) {
                    // @todo: TOAST ERROR
                    console.error(response.data);
                    return response;
                }
                return response.data;
            })
            .catch((err) => {
                if (error_handler) {
                    return error_handler(err);
                }
                const error_type = err?.response?.data?.error;
                const error_msg = err?.response?.data?.message;
                if (error_type === 'WebsiteError') {
                    if (err?.response?.status === 404) { // HTTP 404 - Not Found -> normal error
                        return {not_found: true};
                    } else if (err?.response?.status === 403) { // HTTP 403 - Not allowed (e.g. not published news article & non-admin) -> behave as not found
                        return {not_found: true};
                    }
                    alert('Virhe: ' + error_msg); // @todo: BETTER ERROR UX
                    return err;
                }
                console.error('API request failed!', err);
                return err;
            });
    }

    deleteFromApi(uri) {
        return axios
            .delete(process.env.REACT_APP_API_URL + uri, { withCredentials: true })
            .then((response) => {
                // @todo: check for errors and toast them?
                if (!response.data) {
                    // @todo: TOAST ERROR
                    console.error(response.data);
                    return response;
                }
                return response.data;
            })
            .catch((err) => {
                // @todo: TOAST ERROR PROPERLY

                // @todo: NEED TO CHECK THE ERROR MESSAGE HERE?!
                const error_type = err?.response?.data?.error;
                const error_msg = err?.response?.data?.message;
                if (error_type === 'WebsiteError') {
                    alert(error_msg); // @todo: BETTER ERROR UX
                    return err;
                }
                console.error('API request failed!', err);
                return err;
            });
    }

    uploadMedia(form_data, purpose) {
        if (purpose) {
            purpose = '&purpose=' + encodeURIComponent(purpose);
        } else {
            purpose = '';
        }
        return this.postToApi('website-api.php?action=upload:media' + purpose, form_data);
    }

    postComment(comment) {
        return this.postToApi('website-api.php?action=write:comment', comment);
    }

    submitReservation(reservation) {
        return this.postToApi('website-api.php?action=save:reservation', reservation);
    }

    markNotificationRead(notification_id) {
        return this.postToApi('website-api.php?action=mark:notification', notification_id);
    }

    createAccount(user_data) {
        return this.postToApi('website-api.php?action=create:account', user_data);
    }

    changePassword(user_data) {
        return this.postToApi('website-api.php?action=password:account', user_data);
    }

    changeEmail(user_data) {
        return this.postToApi('website-api.php?action=email:account', user_data);
    }

    changeUsername(user_data) {
        return this.postToApi('website-api.php?action=username:account', user_data);
    }

    changeUserInfo(user_data) {
        return this.postToApi('website-api.php?action=userinfo:account', user_data);
    }

    changeNewsletterSubscription(user_data) {
        return this.postToApi('website-api.php?action=newsletter:account', user_data);
    }

    login(user_data) {
        return this.postToApi('website-api.php?action=login:account', user_data);
    }

    likeResource(payload) {
        return this.postToApi('website-api.php?action=like:resource', payload);
    }

    dislikeResource(payload) {
        return this.postToApi('website-api.php?action=dislike:resource', payload);
    }

    postToApi(uri, data) {
        const config = {
            headers: {
                /*header1: value1,
                header2: value2*/
            },
            withCredentials: true,
        };
        if (data instanceof FormData) {
            config['headers']['Content-Type'] = 'multipart/form-data';
        }
        return axios
            .post(process.env.REACT_APP_API_URL + uri, data, config)
            .then((response) => {
                // @todo: check for errors and toast them?
                if (!response.data) {
                    // @todo: TOAST ERROR
                    console.error(response.data);
                    return response;
                }
                return response.data;
            });
    }

    receiveUpdates() {
        let page;
        if (window._communityIndexPagination > 0) {
            page = window._communityIndexPagination;
        }
        return this.getFromApi('website-api.php?action=receive:updates&path=' + encodeURIComponent(window.location.pathname) + (page > 0 ? '&communityPage=' + encodeURIComponent(page) : ''));
    }

    getTournamentMatches(tournament_id) {
        return this.getFromApi('website-api.php?action=matches:tournament&tournament_id=' + encodeURIComponent(tournament_id));
    }

    getAccount(id, user_type) {
        return this.getFromApi('website-api.php?action=get:account&id=' + encodeURIComponent(id) + '&type=' + encodeURIComponent(user_type));
    }

    getSsoLoginUrl(provider) {
        return this.getFromApi('website-api.php?action=init:sso&provider=' + encodeURIComponent(provider));
    }

    ssoLogin(provider, code, scope, state) {
        return this.getFromApi('website-api.php?action=exchange:sso&provider=' + encodeURIComponent(provider) + '&code=' + encodeURIComponent(code) + '&state=' + encodeURIComponent(state) + '&scope=' + encodeURIComponent(scope));
    }

    getResource(id) {
        return this.getFromApi('website-api.php?action=get:resource&id=' + encodeURIComponent(id));
    }

    getReservations(from_ts, space) {
        return this.getFromApi('website-api.php?action=get:reservations&from_ts=' + encodeURIComponent(from_ts) + (space ? '&space=' + encodeURIComponent(space) : ''));
    }

    getResourceEmbed(url, error_handler) {
        return this.getFromApi('website-api.php?action=getEmbed:resource&url=' + encodeURIComponent(url), error_handler);
    }

    deleteResource(id) {
        return this.deleteFromApi('website-api.php?action=delete:resource&id=' + encodeURIComponent(id));
    }

    getResourceIndex(resource_type) {
        return this.getFromApi('website-api.php?action=get:resources&type=' + encodeURIComponent(resource_type));
    }

    getPastBroadcasts() {
        return this.getFromApi('website-api.php?action=get:broadcasts');
    }

    getComments(id) {
        return this.getFromApi('website-api.php?action=get:comments&resource_id=' + encodeURIComponent(id));
    }

    getLatestNews(ts, tag) {
        let query_str = 'ts=' + encodeURIComponent(ts ? ts : '');
        if (tag) {
            query_str += '&tag=' + encodeURIComponent(tag);
        }
        return this.getFromApi('website-api.php?action=getLatest:news&' + query_str);
    }

    acquireSession() {
        const cookies = new Cookies();
        // @todo: CACHE VISITOR DATA
        return this.getFromApi('website-api.php?action=acquire:session').then((data) => {
            if (!data || !data.id || !data.CSRF_TOKEN) {
                // @todo: TOAST ERROR
                console.error(data);
                return;
            }
            cookies.set('PL_SESSION', data.id, { path: '/' });
            cookies.set('PL_SESSION_CSRF', data.CSRF_TOKEN, { path: '/' });

            if (window.gtag && data?.account?.id) {
                gtag('config', 'G-K0JS756DKW', {
                    'user_id': data?.account?.id
                }); // Report user id
            }
            return data;
        });
    }
}

export default WebsiteApi;
