import './PageCommon.css';
import React from 'react';
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";

class TournamentPlayerPage extends NewsPage {
    resource_type = 'team:player';

    constructor(props) {
        super(props);
        this.resource_type = 'team:player';
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Kilpapelaaja-sivu', 'Kilpapelaaja-sivua', 'Kilpapelaaja: ' + this.props.uri];
        return forms[form];
    }
}

const TournamentPlayerPageWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <TournamentPlayerPage {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default TournamentPlayerPageWithContext;
