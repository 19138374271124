import React from 'react';
import Header from "./navigation/Header";
import { Outlet } from "react-router-dom";
import Footer from "./navigation/Footer";

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="App">
                <div data-desktop-ad-unit-id="/22871113322/pelaajatcom_rich_media_1_1"
                     data-mobile-ad-unit-id="/22871113322/pelaajatcom_rich_media_1_1_mobile"></div>
                <Header/>

                <main className="Main-canvas">
                    <Outlet/>
                </main>

                <Footer/>
            </div>
        );
    }
}

export default Page;
