import './PageCommon.css';
import React from 'react';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../App";
import {NewsPage} from "./NewsPage";
import {useParams} from "react-router";

class ArticlePage extends NewsPage {
    constructor(props) {
        super(props);
        this.resource_type = 'article';
        this.componentDidUpdate({}, {}, {});
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Sivu', 'Sivua', 'Sivut'];
        return forms[form];
    }
}

const ArticlePageWithContext = (props) => {
    const { uri } = useParams();
    let forceRedirect = null;
    switch (uri) {
        // Old Wordpress site URIs
        case 'counter-strike':
        case 'videot':
            forceRedirect = `/kategoria/${uri}`.replace('tag/', '');
            break;
        default:
            break;
    }
    if (forceRedirect) {
        window.location.href = forceRedirect;
    }
    if (!props?.uri) {
        props.uri = uri;
    }
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <ArticlePage {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};
export default ArticlePageWithContext;
