import './HomePage.css';
import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import {AppContext} from "../App";
import errorImage from "../images/plpt_500.avif";
import Header from "../navigation/Header";
import Footer from "../navigation/Footer";
import WebsiteApi from "../WebsiteApi";
import {useNavigate} from "react-router-dom";
import Tools from "../tools/Tools";

class ErrorPage extends React.Component {
    _initialized = false;

    _getApi() {
        return new WebsiteApi();
    }

    getContentColumnContents() {
        return (
            <div>
                <img src={errorImage} alt="Virheviesti: Sivusto ei toimi" style={{maxWidth: '100%'}} /><br />
                <h2>Vituix män! Ei toimi. Kaput.</h2>
            </div>
        );
    }

    componentDidMount() {
        if (this._initialized) {
            return;
        }
        this._initialized = true;
        const path = window.location.pathname;
        const url = encodeURIComponent(path);
        this._getApi().getResource(url).then((data) => {
            if (data?.not_found || data?.response?.status > 299) {
                // An error occurred -> ignore
                // ..make sure "noindex" is defined when 404
                Tools.declarePageNoIndexToRobots(true);
                return data;
            }
            if (data?._redirect && data?.link) {
                // Resource is accessed by old URI, redirect!
                if (data.link === path) {
                    return data; // Same URI, do not redirect to avoid loops..
                }
                this.props.navigate(data.link);
                return data;
            }
            // ..well shit..some other error then
            Tools.declarePageNoIndexToRobots();
            return data;
        });
    }

    render() {
        return (
            <div className="App">
                <Header/>

                <main className="Main-canvas">
                    <div>
                        <ContentColumn content={this.getContentColumnContents()}/>
                    </div>
                </main>

                <Footer/>
            </div>
        );
    }
}

const ErrorPageWithContext = (props) => {
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <ErrorPage {...props} navigate={navigate} context={context}/>
        }}
    </AppContext.Consumer>)
};

export default ErrorPageWithContext;
