import './ReservationCalendar.css';
import ReservationCalendar from "./ReservationCalendar";

class SaunaReservationCalendar extends ReservationCalendar {
    getReservationCalendarType() {
        return 'SAUNA';
    }

    maximumParticipantCount() {
        return 25;
    }
}

export default SaunaReservationCalendar;
