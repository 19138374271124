import React from 'react';
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class WarningNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: this.props?.mode || 'failure',
            title: this.props?.title || 'Viesti',
        };
    }

    static getInfo() {
        return {
            'name': 'Ilmoitus',
            'description': 'Ilmoitus, esimerkiksi virheviesti',
            parameters: {
                mode: ['warning', 'success', 'failure'],
            },
            init: (props) => {
                return (<WarningNote {...props} />);
            },
        }
    }

    render() {
        if (!this.state?.mode && !this.state?.title) {
            return null;
        }
        let css_class = 'User-Note';
        let icon_element = null;
        switch (this.state.mode) {
            case 'success':
                css_class += ` Message-Successful`;
                icon_element = (<FontAwesomeIcon icon={icon({name: 'circle-check'})} />);
                break;
            case 'warning':
                css_class += ` Message-Warning`;
                icon_element = (<FontAwesomeIcon icon={icon({name: 'circle-exclamation'})} />);
                break;
            case 'progress':
                css_class += ` Message-Progress`;
                icon_element = (<FontAwesomeIcon icon={icon({name: 'gear'})} />);
                break;
            case 'failure':
            case 'error':
            default:
                css_class += ` Message-Failure`;
                icon_element = (<FontAwesomeIcon icon={icon({name: 'circle-xmark'})} />);
                break;
        }
        return (
            <div className={css_class}>
                {icon_element} {this.state.title}
            </div>
        );
    }
}

export default WarningNote;
