import './UserModal.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import {useParams} from "react-router";
import { UserModal } from "./UserModal";
import { ViewUserModal } from "./ViewUserModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import TagBox from "../boxes/TagBox";

class ViewCreatorModal extends ViewUserModal {
    constructor(props) {
        super(props);
        this.user_type = 'creator';
        this.componentDidUpdate({}, {}, {});
    }

    getPageMetadata() {
        const visitor_account = this.state.account;
        if (!visitor_account) {
            return {};
        }
        return {
            title: 'Kirjoittaja: ' + this._getUserName(visitor_account),
            ogDesc: visitor_account?.creator?.description || 'Pelaajat.com toimituksen kirjoittaja ' + this._getUserName(visitor_account),
            ldType: 'WebPage',
            authorName: this._getUserName(visitor_account),
            authorImg: visitor_account?.account?.img,
            authorLd: visitor_account?.account?.creator?.ld,
            excludeFromIndex: false,
        };
    }

    render() {
        if (this.initializing || this.state.is_loading) {
            return this.getLoadingView();
        }
        const visitor_account = this.state.account;
        const registered_account = visitor_account?.account;
        if (!visitor_account?.anonymous_name || !registered_account?.id) {
            return (
                <>
                    Myyrä on kolossaan, kirjoittajaa ei löytynyt!
                </>
            );
        }
        const stats_and_tags = UserModal.getUserStats(visitor_account);
        return (
            <div className="Modal-Wrapper" onClick={() => this.props.navigate('/')}>
                <div className="UserModal" onClick={e => e.stopPropagation()}>
                    <div className="User-Profile-Container">
                        <div className="Left-Column">
                            <h2>Kirjoittaja: {this._getUserName(visitor_account)}</h2>
                            {this._getUserAvatar(registered_account)}
                            {stats_and_tags}
                        </div>
                        <div className="Middle-Column">
                            <p>{visitor_account.creator?.description}</p>
                            {visitor_account.creator?.ld?.sameAs ? (<p>Seuraa minua: <a href={visitor_account.creator.ld.sameAs[0]} target="_blank"><FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} /></a></p>) : ''}

                            <div style={{position: 'relative'}}>
                                <TagBox tag={'author:' + visitor_account.account?.id} title={this._getUserName(visitor_account)} />
                            </div>
                        </div>
                        <div className="Right-Column">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function ViewCreatorModalWithNavigateAndContext(props) {
    const { id } = useParams();
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <ViewCreatorModal {...props} navigate={navigate} context={context} id={id} />
        }}
    </AppContext.Consumer>)
}

export default ViewCreatorModalWithNavigateAndContext;