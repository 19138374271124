
const websiteBaseUrl = (process.env.REACT_APP_BASE_URL || '/').replace(/\/$/, '');

class MarkupTools {
    static Linkify(text) {
        if (!text || !text.replace) {
            return text;
        }

        // Shorten too long texts
        if (text.length > 160) {
            text = text.substring(0, 160) + '<span>... <a href="" onclick="event.preventDefault(); let p = this.parentElement; p.nextElementSibling.style.display = \'inline\'; p.remove(); return false;">(lue kokonaan)</a></span><span style="display: none;">' + text.substring(160) + '</span>';
        }

        // Linkify categories
        const categoryRegex = /(#\b[a-z0-9\-]{2,64}\b)/ig;
        text = text.replace(categoryRegex, function(category) {
            if (!category) {
                return category;
            }
            return '<a href="' + websiteBaseUrl + '/kategoria/' + ('' + category).replace('#', '') + '">' + category + '</a>';
        });
        // @todo: LINKIFY OTHER THINGS, E.G. @MENTIONS AND OTHER THINGS?
        return text;
        /*const urlRegex = /(\bhttps:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url.replace('https://', '') + '</a>';
        });*/
    }
}

export default MarkupTools;
