import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import {AppContext} from "../App";
import NewsBox from "../boxes/NewsBox";
import Loading from "../Loading";

class NewsIndexPage extends React.Component {
    constructor(props) {
        super(props);
        this._props = props;
        this.state = {};

        if (window._forceUpdate && !this.props?.context?.components?.news) {
            window._forceUpdate();
        }
    }

    getContentColumnContents() {
        const news = this.props?.context?.components?.news;
        if (!news || !(news.length > 1)) {
            return (
                <Loading inline={true} />
            );
        }
        return (
            <>
                <NewsBox fullpage="true" />
            </>
        );
    }

    render() {
        return (
            <div className="Content">
                <ContentColumn content={this.getContentColumnContents()} />
                <SideBar />
            </div>
        );
    }
}

const NewsIndexPageWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <NewsIndexPage {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default NewsIndexPageWithContext;
