import './NewsBox.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";
import {NewsBox} from "./NewsBox";

class TagBox extends NewsBox {
    constructor(props) {
        super(props);
        this.state = {
            'news': [],
        };
        this.loadMore();
    }

    getTag() {
        return this.props?.tag;
    }

    getTitle() {
        if (this.props?.title) {
            return this.props.title;
        }
        return '#' + this.getTag();
    }
}

const TagBoxWithContext = (props) => {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <TagBox {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default TagBoxWithContext;

